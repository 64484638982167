import {
  ActionIcon,
  Burger,
  Button,
  Divider,
  Drawer,
  Group,
  useComputedColorScheme,
  useMantineColorScheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconMoonStars, IconSun } from "@tabler/icons-react";
import throttle from "lodash.throttle";
import { useSession } from "next-auth/react";
import Link from "next/link";
import * as React from "react";
import css from "./Header.module.css";
import cx from "clsx";
import { Logo } from "../Logo";

type MenuItem = { title: string; href: string };

const MENU_LIST: MenuItem[] = [
  { title: "Главная", href: "/" },
  { title: "Блог", href: "/blog" },
  { title: "Контакты", href: "/contacts" },
];

export const Header = () => {
  const [isSticky, setIsSticky] = React.useState(false);

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const { colorScheme, setColorScheme } = useMantineColorScheme();

  const computedColorScheme = useComputedColorScheme("light");

  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === "dark" ? "light" : "dark");
  };

  const { data } = useSession();

  React.useEffect(() => {
    const isSticky = throttle(
      () => {
        const scrollTop = Math.round(window.scrollY);

        if (scrollTop >= 65) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      },
      300,
      { leading: false }
    );

    window.addEventListener("scroll", isSticky, { passive: true });
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  return (
    <header className={css.height}>
      <div className={cx(css.height, css.backColor, isSticky && css.sticky)}>
        <nav className={cx(css.navigation, css.height)}>
          <Logo />
          <div className={cx(css.list, css.hiddenMobile)}>
            {MENU_LIST.map((menu) => (
              <NavItem {...menu} key={menu.title} />
            ))}
          </div>
          <Group justify="right" className={css.hiddenMobile}>
            {data?.user?.isAdmin && (
              <Button variant="subtle">
                <Link href="/admin" prefetch={false}>
                  Админ
                </Link>
              </Button>
            )}
            <ActionIcon
              variant="transparent"
              onClick={() => toggleColorScheme()}
              size="xl"
            >
              {colorScheme === "dark" ? (
                <IconSun size={20} stroke={1.5} />
              ) : (
                <IconMoonStars size={20} stroke={1.5} />
              )}
            </ActionIcon>
          </Group>
          <Group justify="right" className={css.hiddenDesktop}>
            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={css.hiddenDesktop}
            />
          </Group>
        </nav>
      </div>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        transitionProps={{
          duration: 0,
        }}
        size="100%"
        padding="md"
        title="Навигация по сайту"
        className={css.hiddenDesktop}
        zIndex={1000000}
      >
        <Divider my="sm" className={css.divider} />

        {MENU_LIST.map((menu) => (
          <Link
            href={menu.href}
            className={css.mobileLink}
            key={menu.title}
            prefetch={false}
          >
            {menu.title.toUpperCase()}
          </Link>
        ))}

        <Divider my="sm" className={css.divider} />

        <Group justify="center" grow pb="xl" px="md">
          {data?.user?.isAdmin && (
            <Button variant="subtle">
              <Link href="/admin" prefetch={false}>
                Админ
              </Link>
            </Button>
          )}
          <ActionIcon
            variant="transparent"
            onClick={() => toggleColorScheme()}
            size="xl"
          >
            {colorScheme === "dark" ? (
              <IconSun size={20} stroke={1.5} />
            ) : (
              <IconMoonStars size={20} stroke={1.5} />
            )}
          </ActionIcon>
        </Group>
      </Drawer>
    </header>
  );
};

const NavItem: React.FC<MenuItem> = ({ title, href }) => {
  return (
    <Link href={href} className={css.link} prefetch={false}>
      {title.toUpperCase()}
    </Link>
  );
};
