import { FileStorage } from "./services/FileService";

export const CATEGORIES: {
  category: FileStorage["category"] | null;
  title: string;
}[] = [
  { title: "Все", category: null },
  { title: "Dota 2", category: "dota2" },
  { title: "Dead by Daylight", category: "dbd" },
  { title: "Смайлики на заказ", category: "ordered-emotes" },
  { title: "Смайлики для дискорда", category: "discord-emotes" },
  { title: "Аватарки", category: "avatars" },
  { title: "Разное", category: "unclassified" },
];

export const getCategoryTitle = (category: FileStorage["category"]) => {
  return (
    CATEGORIES.find((c) => c.category === category)?.title || "Нет категории"
  );
};

export const SOCIAL_URLS = {
  VK: "https://vk.com/wolfofmagdalena",
  STEAM: "https://steamcommunity.com/id/wofm",
  DISCORD: "https://discord.gg/MErdawhfv6",
  TWITCH: "https://www.twitch.tv/wolfisshe",
};
