import { Title, Mark, Text } from "@mantine/core";
import css from "./WelcomeTextBlock.module.css";

export function WelcomeTextBlock() {
  return (
    <div className={css.textWrapper}>
      <Title order={1} ta="center" py="40px" px="md">
        Привет! Я -{" "}
        <Text variant="gradient" component="span">
          Катя
        </Text>{" "}
        из Краснодара: <br />{" "}
        <Text
          variant="gradient"
          component="span"
          gradient={{ from: "pink", to: "orange" }}
        >
          рисую,
        </Text>{" "}
        <Text
          variant="gradient"
          component="span"
          gradient={{ from: "#C0F12C", to: "#3FFF39" }}
        >
          играю,
        </Text>{" "}
        вкусно{" "}
        <Text
          variant="gradient"
          component="span"
          gradient={{ from: "#EA00FF", to: "#FF93FF" }}
        >
          готовлю
        </Text>{" "}
        <br />А еще я теперь <Mark color="gray">блогерка</Mark> c:
      </Title>
    </div>
  );
}
