import { ActionIcon, Container, Divider, Group, Text } from "@mantine/core";
import { Logo } from "../Logo";
import { SocialIcons } from "../Socials";
import { IconRss } from "@tabler/icons-react";
import Link from "next/link";
import Script from "next/script";
import Image from "next/image";
import css from "./Footer.module.css";

export function Footer() {
  return (
    <footer>
      <Container className={css.inner}>
        <Logo />
        <Text fz={14}>© {new Date().getFullYear()} Wofm art</Text>
        <Group gap="1px" justify="right" align="center">
          <SocialIcons />
          <Divider orientation="vertical" mx={8} />
          <a href="https://www.liveinternet.ru/click" target="_blank">
            <Image
              id="licntF9FA"
              width="31"
              height="31"
              style={{ border: 0 }}
              title="LiveInternet"
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAIBTAA7"
              alt=""
            />
          </a>
          <Script id="liveinternet">
            {
              '(function(d,s){d.getElementById("licntF9FA").src="https://counter.yadro.ru/hit?t44.6;r"+escape(d.referrer)+((typeof(s)=="undefined")?"":";s"+s.width+"*"+s.height+"*"+(s.colorDepth?s.colorDepth:s.pixelDepth))+";u"+escape(d.URL)+";h"+escape(d.title.substring(0,150))+";"+Math.random()})(document,screen)'
            }
          </Script>
          <Link href="/rss.xml" prefetch={false}>
            <ActionIcon
              size="lg"
              ml="xs"
              variant="subtle"
              color="inherit"
              className={css.icons}
            >
              <IconRss stroke={1.5} size="1.05rem" />
            </ActionIcon>
          </Link>
        </Group>
      </Container>
    </footer>
  );
}
