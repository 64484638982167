import { BlogData, BlogDataExtended } from "./components/Admin/Blog/interface";
import { Tag, TagResponse, WithId } from "./types/interface";
import { getCurrentUrl } from "./utils/getCurrentUrl";

/** Создание новой записи в блоге */
export const createBlogPost = async (
  data: Partial<BlogData> & { author: string }
) => {
  const response = await fetch(`${getCurrentUrl()}/api/blog`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response.json();

  return result as BlogDataExtended;
};

/** Изменение записи в блоге */
export const updateBlogPost = async (data: Partial<BlogData>) => {
  const response = await fetch(`${getCurrentUrl()}/api/blog`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response.json();

  return result as { result: "ok" };
};

/** Удаление записи с id */
export const deleteBlogPost = async (id: string) => {
  const response = await fetch(`${getCurrentUrl()}/api/blog`, {
    method: "DELETE",
    body: JSON.stringify({ id }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();

  return result as { result: "ok" };
};

/** Получение всех записей в блоге */
export const getBlogPosts = async () => {
  const response = await fetch(`${getCurrentUrl()}/api/blog`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();

  return result as (Partial<BlogDataExtended> & { id: string })[];
};

export const getTags = async () => {
  const response = await fetch(`${getCurrentUrl()}/api/tags`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const result = await response.json();

  return result as TagResponse[];
};

/** Создание нового тега */
export const createTag = async (data: Tag) => {
  const response = await fetch(`${getCurrentUrl()}/api/tags`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response.json();

  return result;
};

/** Изменение тега */
export const updateTag = async (data: WithId<Tag>) => {
  const response = await fetch(`${getCurrentUrl()}/api/tags`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response.json();

  return result;
};

/** Удаление тега */
export const deleteTag = async (data: WithId<Tag>) => {
  const response = await fetch(`${getCurrentUrl()}/api/tags`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await response.json();

  return result;
};
