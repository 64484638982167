import { SOCIAL_URLS } from "@/constants";
import { ActionIcon } from "@mantine/core";
import {
  IconBrandDiscord,
  IconBrandSteam,
  IconBrandTwitch,
  IconBrandVk,
} from "@tabler/icons-react";
import Link from "next/link";
import cssFooter from "./Footer/Footer.module.css";

const buttonSize = {
  default: "lg",
  large: void 0,
};

const iconSize = {
  default: "1.05rem",
  large: void 0,
};

const iconStroke = {
  default: 1.5,
  large: void 0,
};

interface SocialIconsProps {
  variant?: "default" | "large";
}

export function SocialIcons({ variant = "default" }: SocialIconsProps) {
  return (
    <>
      <Link href={SOCIAL_URLS.VK} prefetch={false}>
        <ActionIcon
          size={buttonSize[variant]}
          variant="subtle"
          className={cssFooter.icons}
        >
          <IconBrandVk size={iconSize[variant]} stroke={iconStroke[variant]} />
        </ActionIcon>
      </Link>
      <Link href={SOCIAL_URLS.STEAM} prefetch={false}>
        <ActionIcon
          size={buttonSize[variant]}
          variant="subtle"
          className={cssFooter.icons}
        >
          <IconBrandSteam
            size={iconSize[variant]}
            stroke={iconStroke[variant]}
          />
        </ActionIcon>
      </Link>
      <Link href={SOCIAL_URLS.DISCORD} prefetch={false}>
        <ActionIcon
          size={buttonSize[variant]}
          variant="subtle"
          className={cssFooter.icons}
        >
          <IconBrandDiscord
            size={iconSize[variant]}
            stroke={iconStroke[variant]}
          />
        </ActionIcon>
      </Link>
      <Link href={SOCIAL_URLS.TWITCH} prefetch={false}>
        <ActionIcon
          size={buttonSize[variant]}
          variant="subtle"
          className={cssFooter.icons}
        >
          <IconBrandTwitch
            size={iconSize[variant]}
            stroke={iconStroke[variant]}
          />
        </ActionIcon>
      </Link>
    </>
  );
}
