import { ActionIcon } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";

export function Logo() {
  return (
    <Link href="/" prefetch={false}>
      <ActionIcon size={48} variant="subtle">
        <Image src="/logo.png" alt="wofm logo" width={30} height={30} />
      </ActionIcon>
    </Link>
  );
}
