import { BlogDataExtended } from "@/components/Admin/Blog/interface";
import { BlogCard } from "@/components/Blog/BlogCard";
import { Gallery } from "@/components/Gallery/Gallery";
import { Footer } from "@/features/Footer/Footer";
import { Header } from "@/features/Header";
import { WelcomeTextBlock } from "@/features/WelcomeTextBlock";
import { TAGS_KEY } from "@/lib/queryKeys";
import { FileStorage } from "@/services/FileService";
import { getAllImagesPrerender } from "@/utils/getAllImagesPrerender";
import { getFirestoreBlogs } from "@/utils/getFirestoreBlogs";
import { getFirestoreTags } from "@/utils/getFirestoreTags";
import { Title } from "@mantine/core";
import { QueryClient, dehydrate } from "@tanstack/react-query";
import cx from "clsx";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import css from "./index.module.css";

const DynamicParticalLogo = dynamic(
  () => import("@/components/ParticleLogo").then((mod) => mod.ParticalLogo),
  {
    ssr: false,
    loading: () => <div style={{ height: "200px" }}></div>,
  }
);

export default function Home(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  return (
    <main>
      <NextSeo
        title="Wofm | Главная"
        description="Привет! Я Катя из Краснодара. Рисую, играю, вкусно готовлю. А еще я теперь блогерка"
        openGraph={{
          images: props.images.map((img) => ({
            url: img.url,
            alt: img.description,
            height: img.height,
            width: img.width,
          })),
        }}
      />
      <Header />
      <section className={css.logo}>
        <DynamicParticalLogo />
        <WelcomeTextBlock />
      </section>

      <section className={css.section}>
        <div className={css.content}>
          <Title order={2} ta="center">
            Последние посты
          </Title>
          <div className={css.grid}>
            {props.blogs.map((blog) => (
              <BlogCard {...blog} key={blog.id} ratio={16 / 9} />
            ))}
          </div>
        </div>
      </section>

      <section className={cx(css.section, css.graySection)}>
        <div className={css.content}>
          <Gallery images={props.images} />
        </div>
      </section>

      <Footer />
    </main>
  );
}

export const getStaticProps: GetStaticProps<{
  images: FileStorage[];
  blogs: (Partial<BlogDataExtended> & { id: string })[];
}> = async () => {
  const images: FileStorage[] = await getAllImagesPrerender();
  const blogs = await getFirestoreBlogs({ limit: 6 });

  const queryClient = new QueryClient();

  await queryClient.prefetchQuery({
    queryKey: [TAGS_KEY],
    queryFn: getFirestoreTags,
  });

  return { props: { images, blogs, dehydratedState: dehydrate(queryClient) } };
};
