import { ActionIcon, Divider, Flex, Group, Text, Title } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import css from "./LightBoxHeader.module.css";

interface LightBoxHeaderProps {
  onClose: () => void;
  images: { title: string | undefined; description: string | undefined }[];
  currentIndex: number;
}

export function LightBoxHeader({
  currentIndex,
  images,
  onClose,
}: LightBoxHeaderProps) {
  const image = images[currentIndex];

  return (
    <div className={css.header}>
      <Group py="sm" px="lg">
        <Divider
          orientation="vertical"
          size="lg"
          className={css.hideOnMobile}
          color="cyan"
        />
        <Flex direction="column">
          {image.title && <Title order={2}>{image.title}</Title>}
          {image.description && <Title order={4}>{image.description}</Title>}
        </Flex>
      </Group>

      <Group py="sm" px="lg">
        <Text className={css.hideOnMobile}>
          {currentIndex + 1} / {images.length}
        </Text>

        <Divider
          orientation="vertical"
          size="lg"
          className={css.hideOnMobile}
        />

        <ActionIcon
          variant="transparent"
          size="3rem"
          onClick={onClose}
          className={css.button}
        >
          <IconX size="3rem" />
        </ActionIcon>
      </Group>
    </div>
  );
}
