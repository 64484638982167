import { CATEGORIES } from "@/constants";
import { FileStorage } from "@/services/FileService";
import { ActionIcon, Title } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import dynamic from "next/dynamic";
import Image from "next/image";
import * as React from "react";
import { LightBoxHeader } from "../Lightbox/LightBoxHeader";
import css from "./Gallery.module.css";

interface GalleryProps {
  images: FileStorage[];
}

const DynamicLightbox = dynamic(() => import("react-spring-lightbox"), {
  ssr: false,
});

export const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const [category, setCatregory] = React.useState<
    FileStorage["category"] | null
  >(null);

  const filteredImages = React.useMemo(() => {
    return images.filter((image) => {
      if (category === null) {
        return true;
      }

      return image.category === category;
    });
  }, [category, images]);

  const [isLightboxOpened, setIsLightboxOpened] = React.useState(false);

  const [currentImageIndex, setCurrentIndex] = React.useState(0);

  const gotoPrevious = () => {
    return currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);
  };

  const gotoNext = () => {
    setCurrentIndex((currentIndex) => {
      if (currentIndex + 1 < filteredImages.length) {
        return currentIndex + 1;
      }

      return currentIndex;
    });
  };

  const onClose = () => {
    setIsLightboxOpened(false);
  };

  return (
    <>
      <div className={css.galleryGroups}>
        <Title order={2}>Мои рисуночки</Title>
        {CATEGORIES.map((category) => {
          return (
            <span
              key={category.title}
              className={css.categoryItem}
              onClick={() => {
                setCatregory(category.category);
              }}
            >
              {category.title.toLocaleUpperCase()}
            </span>
          );
        })}
      </div>

      <div className={css.grid}>
        {filteredImages.map((img, index) => (
          <div
            itemScope
            itemType="http://schema.org/ImageObject"
            key={img.name + index}
          >
            <Image
              itemProp="contentUrl"
              className={css.imageItem}
              src={img.url}
              onClick={() => {
                setCurrentIndex(index);
                setIsLightboxOpened(true);
              }}
              title={img.title}
              alt={img.description}
              width={250}
              height={250}
            />

            <meta
              itemProp="author"
              content="Екатерина Секарова - Wofm-art.ru"
            />
            <meta itemProp="name" content={img.title} />
            <meta itemProp="description" content={img.description} />
            {img.created && (
              <meta
                itemProp="datePublished"
                content={new Date(img.created).toISOString()}
              />
            )}
            <meta itemProp="height" content={String(img.height)} />
            <meta itemProp="width" content={String(img.width)} />
          </div>
        ))}
        <DynamicLightbox
          isOpen={isLightboxOpened}
          onPrev={gotoPrevious}
          onNext={gotoNext}
          images={filteredImages.map((img) => ({
            loading: "lazy",
            alt: img.description,
            src: img.url,
          }))}
          renderNextButton={({ canNext }) =>
            canNext && (
              <ActionIcon
                onClick={gotoNext}
                variant="transparent"
                size="3rem"
                className={css.button}
              >
                <IconChevronRight size="3rem" />
              </ActionIcon>
            )
          }
          renderPrevButton={({ canPrev }) =>
            canPrev && (
              <ActionIcon
                onClick={gotoPrevious}
                variant="transparent"
                size="3rem"
                className={css.button}
              >
                <IconChevronLeft size="3rem" />
              </ActionIcon>
            )
          }
          style={{ background: "rgb(0 0 0 / 95%)" }}
          onClose={onClose}
          renderHeader={() => (
            <LightBoxHeader
              onClose={onClose}
              images={filteredImages}
              currentIndex={currentImageIndex}
            />
          )}
          singleClickToZoom={true}
          currentIndex={currentImageIndex}
        />
      </div>
    </>
  );
};
