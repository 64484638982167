import { getTags } from "@/integrations";
import { TAGS_KEY } from "@/lib/queryKeys";
import { Tag, TagResponse } from "@/types/interface";
import { has } from "@/utils/has";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

/** Хук получает на вход список тего string[] и возвращает уже обогащенные */
export function useTags(tagsList: string[] = []) {
  const { data: tagsWithInfo } = useQuery({
    queryKey: [TAGS_KEY],
    queryFn: getTags,
  });

  const tags = tagsList.reduce<(TagResponse | Tag)[]>((acc, tagString) => {
    const tag = tagsWithInfo?.find((i) => i.value === tagString);

    if (tag) {
      acc.push(tag);
    } else {
      acc.push({
        value: tagString,
        label: tagString,
      });
    }

    return acc;
  }, []);

  const normalizeTag = useCallback(
    (tagString: string) => {
      const tag = tagsWithInfo?.find((i) => i.value === tagString);

      return tag ? tag : { value: tagString, label: tagString };
    },
    [tagsWithInfo]
  );

  return { tags, normalizeTag };
}

export const isTagExtended = (o: unknown): o is TagResponse => {
  return has(o, "id") && typeof o.id == "string";
};
