import { isTagExtended, useTags } from "@/hooks/useTags";
import { has } from "@/utils/has";
import { AspectRatio, Card, Group, Text, Title } from "@mantine/core";
import cx from "clsx";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import Image from "next/image";
import Link from "next/link";
import { BlogDataExtended } from "../Admin/Blog/interface";
import css from "./BlogCard.module.css";

dayjs.locale("ru");

export function BlogCard({
  slug,
  title,
  tags,
  images,
  author,
  created,
  ratio,
}: Partial<BlogDataExtended> & { id: string } & { ratio?: number }) {
  const { tags: tagsWithInfo } = useTags(tags);

  const image = images?.[0];

  return (
    <Card p={0} radius={0} className={cx(css.card, css.flexColumn)}>
      {image && (
        <div className={css.imageContainer}>
          <Link href={`/blog/${slug}`} prefetch={false}>
            {typeof ratio === "number" ? (
              <AspectRatio ratio={ratio}>
                <Image
                  src={image.url}
                  title={image.title}
                  fill={true}
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  style={{ objectFit: "contain" }}
                  alt={image.description}
                />
              </AspectRatio>
            ) : (
              <Image
                src={image.url}
                title={image.title}
                width={image.width}
                height={image.height}
                style={{ width: "100%", height: "auto" }}
                alt={image.description}
              />
            )}
          </Link>
        </div>
      )}

      <Group gap="5px">
        {tagsWithInfo?.map((tag, index) =>
          isTagExtended(tag) ? (
            <Link href={`/tag/${tag.value}`} key={index} prefetch={false}>
              <Text
                size="13px"
                color={
                  has(tag, "color") && typeof tag.color === "string"
                    ? tag.color
                    : void 0
                }
              >
                #{tag.label.toUpperCase()}
              </Text>
            </Link>
          ) : (
            <Text size="13px" key={index}>
              #{tag.label.toUpperCase()}
            </Text>
          )
        )}
      </Group>

      <Link href={`/blog/${slug}`} prefetch={false}>
        <Title order={3} mt={8} fw={700} className={css.hoverHighlight}>
          {title}
        </Title>

        <Group
          justify="left"
          color="#666"
          fz="14px"
          className={css.footer}
          mt={6}
        >
          <Text>{author?.split(" ")[0] || "Admin"}</Text>
          <Text> • </Text>
          <Text>{dayjs(created).format("D MMMM YYYY")}</Text>
        </Group>
      </Link>
    </Card>
  );
}
